import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AppContext } from '../../context/app'
import { getAudioTrailerUrl, getImage, getReleaseDate, secondsToHms } from '../../utils/helper'
import './multiPartDetail.css'
import TrailerPlayer from '../player/trailerPlayer'
import useAxios from 'axios-hooks'
import { isEmpty, sortBy } from 'lodash'
import { IoIosPlay } from 'react-icons/io'
import { BsHeart, BsHeartFill, BsPlusLg } from 'react-icons/bs'
import { MdOutlineLibraryAddCheck } from 'react-icons/md'
import { PARENT_DETAIL_CONTENT, RELATED_CONTENT } from '../../utils/apiUrls'
import { ContentContext } from '../../context/content'
import { useTranslation } from 'react-i18next'
import CustomImage from '../../components/customImage/customImage'
import VideoPlaceholder from '../../assets/video-content.png'
import ShowMoreText from 'react-show-more-text'
import { withTheme } from 'theming'
import MultiContentWrapper from './_multiPartDetailstyle'
import { SubscriptionContext } from '../../context/subscription'
import { useInterval } from '../../hooks/useInterval'
import AddWatchlist from '../../assets/add-watchlist.svg'
import AddFavourite from '../../assets/add-favourite.svg'
import AddedWatchlist from '../../assets/added-watchlist.svg'
import AddedFavourite from '../../assets/added-favourite.svg'
import AudioTrailerPlayer from '../../components/audioPlayer/audioTrailerPlayer'
import RelatedVideos from '../contentDetail/relatedVideos'
import CustomMetadata from '../../components/customMetadata/customMetadata'

const MultiPartDetail = ({ theme }) => {
  const contentId = useParams().contentId
  const {
    isCustomTemplate,
    isLogin,
    setLoader,
    setOpenLoginModal,
    setModalType,
    accountObj,
    isBrowser,
  } = useContext(AppContext)
  const { setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const { t } = useTranslation()
  const { isInFavoriteList, isInWatchList, addRemoveFavouriteList, addRemoveWatchList } =
    useContext(ContentContext)
  const [detailObj, setDetailObj] = useState()
  const [multiToggle, setMultiToggle] = useState('MULTI')
  const [childContentList, setChildContentList] = useState([])

  const navigate = useNavigate()

  const [{ data: relatedVideos, loading: relatedVideosLoading }] = useAxios({
    url: `${RELATED_CONTENT}${contentId}`,
    method: 'post',
    data: {},
  })

  const [{ data: contentDetail, loading: contentLoading }, contentDetailCall] = useAxios({
    url: `${PARENT_DETAIL_CONTENT}${contentId}`,
    method: 'get',
  })

  useInterval(async () => {
    if (accountObj?.od_resource_allowances?.content_refresh) {
      await contentDetailCall()
    }
  }, 10000)

  useEffect(() => {
    contentDetailCall()
  }, [contentId, isLogin])

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      const contentObj = contentDetail.data
      setDetailObj(contentObj.parent_content)
      setChildContentList(contentObj.child_content_list)
    }
  }, [contentDetail])

  useEffect(() => {
    setLoader((isEmpty(detailObj) && contentLoading) || relatedVideosLoading)
  }, [contentLoading, relatedVideosLoading])

  const handleNavigate = (contentId, watch) => {
    if (watch) {
      navigate(`/content/${contentId}?watch=true`)
      return
    }
    navigate(`/content/${contentId}`)
  }

  const handleCategoryClick = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const handleSubCategory = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const isPlanExist = () => {
    return detailObj.is_restricted ? !detailObj?.is_purchased_required : true
  }

  const purchaseBundle = () => {
    if (!isLogin) {
      setModalType('login')
      setOpenLoginModal(true)
    } else {
      let activeSub = [...detailObj.active_subscription.map((item) => item.id)]
      let activePPV = [...detailObj.active_ppv_subscription.map((item) => item.id)]
      setRedirectionUrl(window.location.pathname)
      setSubscriptions([...activeSub, ...activePPV])
      setOpenSubscriptionModal(true)
    }
  }

  return (
    <MultiContentWrapper theme={theme}>
      {detailObj && (
        <>
          <div className="flex flex-col xl:flex">
            <div className="content-image xl:visible">
              <div className="content-image-div relative">
                {detailObj?.preview ? (
                  detailObj?.trailer_type === 'AUDIO' ? (
                    <AudioTrailerPlayer
                      url={getAudioTrailerUrl(accountObj?.cf_domain_name, detailObj.preview)}
                      detailObj={detailObj}
                      accountObj={accountObj}
                      isCustomTemplate={isCustomTemplate}
                      theme={theme}
                    />
                  ) : (
                    <TrailerPlayer
                      height={'calc(70vw / 1.7777)'}
                      preview={detailObj.preview}
                      poster={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
                      quality={480}
                      type={'CONTENT'}
                      stream={detailObj.stream}
                      videoType={detailObj.video_type}
                    />
                  )
                ) : (
                  <CustomImage
                    imageUrl={getImage(
                      accountObj?.cf_domain_name,
                      detailObj?.asset_type === 'AUDIO' ? detailObj?.thumbnail : detailObj?.poster,
                    )}
                    loaderClass="content-loader"
                    imgAlt="content"
                    errorPlaceholder={VideoPlaceholder}
                  />
                )}
              </div>
            </div>

            <div
              className={`z-10  mt-2 ${isBrowser && `xl:absolute`} pl-5 xl:pl-16 w-11/12`}
              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            >
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                className={'text-3xl xl:text-4xl font-semibold'}
              >
                {detailObj?.title}
              </p>
              <div className="flex items-center mt-5">
                {detailObj.release_time && (
                  <div className={'flex '}>
                    <p className={' font-semibold'}>{t('release_date')} :</p>
                    <p className={'ml-2'}>{getReleaseDate(detailObj.release_time)}</p>
                  </div>
                )}
                {isLogin && (
                  <div className="flex">
                    <div
                      className={`" cursor-pointer  ${
                        detailObj.release_time ? 'ml-4 xl:ml-8' : ''
                      } "`}
                    >
                      {isInWatchList(contentId) ? (
                        <img
                          src={AddedWatchlist}
                          className="h-8 w-8"
                          onClick={() => addRemoveWatchList(contentId)}
                          alt="AddedWatchlist"
                        />
                      ) : (
                        <img
                          src={AddWatchlist}
                          className="h-8 w-8"
                          onClick={() => addRemoveWatchList(contentId)}
                          alt="AddWatchlist"
                        />
                      )}
                    </div>
                    <div className="cursor-pointer ml-2 xl:ml-4 ">
                      {isInFavoriteList(contentId) ? (
                        <img
                          src={AddedFavourite}
                          className="h-8 w-8 "
                          onClick={() => addRemoveFavouriteList(contentId)}
                          alt="AddedFavourite"
                        />
                      ) : (
                        <img
                          src={AddFavourite}
                          className="h-8 w-8 "
                          onClick={() => addRemoveFavouriteList(contentId)}
                          alt="AddFavourite"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!isPlanExist() && (
                <button
                  className="px-4 py-1 mt-4 focus:outline-none"
                  style={{ color: '#fff', backgroundColor: '#1976d2', borderRadius: '6px' }}
                  onClick={purchaseBundle}
                >
                  {'Buy Bundle'}
                </button>
              )}
              <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className={`mt-5 ${
                  isBrowser ? 'xl:w-2/5' : 'w-full'
                }  text-lg mr-5  description-color`}
                anchorClass={`text-sm description-see-more-color`}
                expanded={false}
                truncatedEndingComponent={'... '}
              >
                <p style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}>
                  {detailObj?.description}
                </p>
              </ShowMoreText>
              <div className={'xl:w-3/5  mt-5 '}>
                {Object.keys(detailObj.artists).map((artist, index) => (
                  <div
                    className={'flex'}
                    key={index}
                    style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  >
                    <p className={'text-white font-semibold text-sm md:text-md w-4/12 md:w-2/12'}>
                      {artist}
                    </p>
                    <div
                      style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                      className={
                        '  text-sm md:text-md  font-semibold w-8/12 md:w-10/12 flex flex-col lg:flex-row '
                      }
                    >
                      <p className=" text-right lg:text-left">
                        {detailObj.artists[artist].map((item, index) => (
                          <span
                            key={index}
                            onClick={() => navigate(`/artist/detail/${item.id}`)}
                            className={'cursor-pointer hover:underline'}
                          >
                            {index !== 0 && <span className={''}>{' , '}</span>}
                            {item.name}
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                ))}
                <div className={'flex'}>
                  <p
                    style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    className={' font-semibold text-sm md:text-md w-4/12 md:w-2/12'}
                  >
                    {t('category')}
                  </p>
                  <div
                    style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                    className={
                      ' text-sm md:text-md font-semibold w-8/12 lg:w-10/12 flex flex-col xl:flex-row'
                    }
                  >
                    <p className=" text-right lg:text-left">
                      {[...detailObj?.category].map((item, index) => (
                        <span
                          key={index}
                          onClick={() => handleCategoryClick(item.id, item.name)}
                          className={'cursor-pointer hover:underline'}
                        >
                          {index !== 0 && <span className={''}>{' , '}</span>}
                          {item.name}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
                {!isEmpty(detailObj.sub_category) && (
                  <div className={'flex'}>
                    <p
                      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                      className={' font-semibold text-sm md:text-md w-4/12 md:w-2/12'}
                    >
                      {t('genre')}
                    </p>
                    <div
                      style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                      className={
                        ' text-sm md:text-md font-semibold w-8/12 md:w-10/12 flex flex-col xl:flex-row'
                      }
                    >
                      <p className=" text-right lg:text-left">
                        {detailObj.sub_category.map((item, index) => (
                          <span
                            key={index}
                            onClick={() => handleSubCategory(item.id, item.name)}
                            className={'cursor-pointer hover:underline'}
                          >
                            {index !== 0 && <span className={''}>{' , '}</span>}
                            {item.name}
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
                {!isEmpty(detailObj.venues) && (
                  <div className={'flex'}>
                    <p
                      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                      className={' font-semibold text-sm md:text-md w-4/12 md:w-2/12'}
                    >
                      {t('venue')}
                    </p>
                    <div
                      style={{ color: isCustomTemplate ? theme.c3 : 'rgb(96,165,250)' }}
                      className={
                        '  text-sm md:text-md font-semibold w-8/12 md:w-10/12 flex flex-col xl:flex-row'
                      }
                    >
                      <p className=" text-right lg:text-left">
                        {detailObj.venues.map((item, index) => (
                          <span
                            key={index}
                            onClick={() => navigate(`/venue/detail/${item.id}`)}
                            className={'cursor-pointer hover:underline'}
                          >
                            {index !== 0 && <span className={''}>{' , '}</span>}
                            {item.name}
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                )}

                {detailObj.custom_metadata_new ? (
                  <CustomMetadata
                    customMetadata={detailObj.custom_metadata_new}
                    nameClass={'font-semibold text-sm md:text-md w-4/12 md:w-2/12'}
                    valueClass={'text-sm md:text-md w-8/12 md:w-10/12 flex flex-col xl:flex-row'}
                    nameColor={isCustomTemplate ? theme.c4 : '#fff'}
                    parentDivClass={'flex'}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:py-8">
            <div className="w-full flex my-4 md:mb-5 justify-center text-base font-semibold md:text-2xl">
              <p
                style={{
                  color:
                    multiToggle === 'MULTI'
                      ? isCustomTemplate
                        ? theme.c3
                        : '#fff'
                      : isCustomTemplate
                      ? theme.c5
                      : 'rgb(156,163,175)',
                  borderColor:
                    multiToggle === 'MULTI'
                      ? isCustomTemplate
                        ? theme.c3
                        : '#fff'
                      : isCustomTemplate
                      ? theme.c5
                      : 'rgb(156,163,175)',
                }}
                className={
                  multiToggle === 'MULTI'
                    ? ' mx-2 md:mx-5 border-b-2 cursor-pointer'
                    : ' mx-2 md:mx-5 cursor-pointer'
                }
                onClick={() => setMultiToggle('MULTI')}
              >
                {t('episodes')}
              </p>
              {relatedVideos && relatedVideos.total > 0 && (
                <p
                  style={{
                    color:
                      multiToggle === 'RELATED'
                        ? isCustomTemplate
                          ? theme.c3
                          : '#fff'
                        : isCustomTemplate
                        ? theme.c5
                        : 'rgb(156,163,175)',
                    borderColor:
                      multiToggle === 'RELATED'
                        ? isCustomTemplate
                          ? theme.c3
                          : '#fff'
                        : isCustomTemplate
                        ? theme.c5
                        : 'rgb(156,163,175)',
                  }}
                  className={
                    multiToggle === 'RELATED'
                      ? ' mx-2 md:mx-5 border-b-2 cursor-pointer'
                      : ' mx-2 md:mx-5 cursor-pointer'
                  }
                  onClick={() => setMultiToggle('RELATED')}
                >
                  {t('related')}
                </p>
              )}
            </div>
            {multiToggle === 'RELATED'
              ? relatedVideos &&
                relatedVideos.total > 0 && (
                  <div
                    style={{
                      minHeight: '48vh',
                      border: '2px solid red',
                      borderColor: 'transparent',
                      maxHeight: '150vh',
                      overflow: 'auto',
                    }}
                    className="w-full relative text-white 2xl:px-24 xl:px-14 md:px-10 px-4 xl:pb-6"
                  >
                    <RelatedVideos contentId={contentId} theme={theme} isMultiPart={true} />
                  </div>
                )
              : !isEmpty(childContentList) &&
                sortBy(childContentList, (o) => o.serial_no).map((info, index) => (
                  <div className=" flex justify-center items-center" key={index}>
                    <div
                      style={{ backgroundColor: isCustomTemplate ? theme.c10 : '#151d27' }}
                      className="grid grid-cols-12 gap-4 bg-opacity-90 w-11/12 shadow rounded-md relative my-1 border border-gray-700"
                    >
                      <div
                        className={`col-span-4 xl:col-span-2 flex justify-center `}
                        onClick={() => handleNavigate(info.content_id, false)}
                      >
                        <CustomImage
                          imageUrl={getImage(accountObj?.cf_domain_name, info.thumbnail)}
                          loaderClass="w-full h-full object-fill p-2 rounded-xl cursor-pointer"
                          imgClass="w-full h-full object-fill p-2 rounded-xl cursor-pointer"
                          imgAlt="movie"
                          errorPlaceholder={VideoPlaceholder}
                        />
                      </div>
                      <div className="col-span-8 xl:col-span-10">
                        <div className="flex justify-between mt-1 md:mt-2">
                          <div className="flex items-center my-1">
                            <div
                              className="bg-[#1976d2] w-5 h-5 md:w-8 md:h-8 rounded-full relative flex justify-center items-center hover:bg-[#4a8ed2] cursor-pointer"
                              onClick={() => handleNavigate(info.content_id, true)}
                            >
                              <IoIosPlay className="w-4 h-4 md:w-6 md:h-6 text-white ml-1" />
                            </div>
                            <p
                              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                              className="text-xs md:text-md md:text-2xl font-semibold mx-2 cursor-pointer line-clamp-1"
                              onClick={() => handleNavigate(info.content_id, false)}
                            >{`${index + 1}. ${info.title}`}</p>
                          </div>
                          {isLogin && (
                            <div
                              style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                              className="flex items-center mx-4"
                            >
                              <div className="ml-2 cursor-pointer">
                                {isInWatchList(info.content_id) ? (
                                  <MdOutlineLibraryAddCheck
                                    className="h-4 w-4 md:h-6 md:w-6"
                                    onClick={() => addRemoveWatchList(info.content_id)}
                                  />
                                ) : (
                                  <BsPlusLg
                                    className="h-4 w-4 md:h-6 md:w-6"
                                    onClick={() => addRemoveWatchList(info.content_id)}
                                  />
                                )}
                              </div>
                              <div className="cursor-pointer ml-4">
                                {isInFavoriteList(info.content_id) ? (
                                  <BsHeartFill
                                    style={{ color: 'red' }}
                                    className="h-4 w-4 md:h-6 md:w-6"
                                    onClick={() => addRemoveFavouriteList(info.content_id)}
                                  />
                                ) : (
                                  <BsHeart
                                    className="h-4 w-4 md:h-6 md:w-6"
                                    onClick={() => addRemoveFavouriteList(info.content_id)}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          style={{ color: isCustomTemplate ? theme.c4 : 'rgb(156,163,175)' }}
                          className="flex items-center mx-1 md:mx-2 text-xs md:text-md "
                        >
                          {info.release_time && (
                            <div className={'flex mt-1'}>
                              <p
                                style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                                className={' font-semibold '}
                              >
                                {t('release_date')} :
                              </p>
                              <p className={'ml-2'}>{getReleaseDate(info.release_time)}</p>
                            </div>
                          )}
                          <p className="ml-4">{secondsToHms(info?.duration)}</p>
                        </div>
                        <div
                          style={{ color: isCustomTemplate ? theme.c4 : 'rgb(156,163,175)' }}
                          className="text-xs md:text-lg mx-1 md:mx-2 mb-2"
                        >
                          <p
                            className={`${isBrowser ? 'line-clamp-2' : 'line-clamp-1'}`}
                          >{`${info.description}`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </>
      )}
    </MultiContentWrapper>
  )
}

export default withTheme(MultiPartDetail)
