import React, { createRef, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { ContentContext } from '../../context/content'
import { SubscriptionContext } from '../../context/subscription'
import ReactAudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { AUDIO_WATCH_URL } from '../../utils/apiUrls'
import useAxios from 'axios-hooks'
import CustomImage from '../customImage/customImage'
import { getImage } from '../../utils/helper'
import './audioPlayer.css'
import { isEmpty } from 'lodash'
import { useInterval } from '../../hooks/useInterval'
import LockIcon from '../../assets/lock-icon.svg'
import LiveCounter from '../../container/contentDetail/liveCounter'
import { useTranslation } from 'react-i18next'
import WaveForm from './waveform'

let flag = false
const AudioPlayer = ({ contentId, detailObj, handleNext = null, handlePrev = null }) => {
  useEffect(() => {
    flag = false
  }, [contentId])

  const [{ data: contentWatchUrl }, getContentWatchUrl] = useAxios(
    {
      url: `${AUDIO_WATCH_URL}${contentId}`,
      method: 'get',
    },

    { manual: true },
  )

  const { isLogin, setOpenLoginModal, setModalType, userObj, setVerifyOtpModalData, accountObj } =
    useContext(AppContext)
  const { setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const { setIsVideoPlaying } = useContext(ContentContext)

  const [playingAudio, setPlayingAudio] = useState(false)

  const [isLive, setIsLive] = useState(false)
  const [endTime, setEndTime] = useState(null)
  const [isContentSubscribed, setIsContentSubscribed] = useState(true)
  const [isEmailUnVerified, setIsEmailUnVerified] = useState(false)
  const { t } = useTranslation()

  const [analyzerData, setAnalyzerData] = useState(null)
  const audioElmRef = createRef()

  const audioAnalyzer = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    const analyzer = audioCtx.createAnalyser()
    analyzer.fftSize = 2048
    const bufferLength = analyzer.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)
    const source = audioCtx.createMediaElementSource(audioElmRef.current.audio.current)
    audioCtx.destination.disconnect()
    analyzer.disconnect()
    source.connect(analyzer)
    source.connect(audioCtx.destination)
    source.onended = () => {
      source.disconnect()
    }

    setAnalyzerData({ analyzer, bufferLength, dataArray })
    flag = true
  }

  useEffect(() => {
    if (contentId) {
      setPlayingAudio(false)
      if (detailObj.audio_type.includes('LIVE')) {
        setEndTime(new Date(detailObj.live_expiry_time + 'Z').getTime())
        if (
          (new Date(detailObj.publish_time + 'Z').getTime() < Date.now() &&
            detailObj.audio_type === 'VOD_LIVE') ||
          (new Date(detailObj.publish_time + 'Z').getTime() < Date.now() &&
            new Date(detailObj.live_expiry_time + 'Z').getTime() > Date.now())
        ) {
          setIsLive(true)
        }
      }
    }
  }, [contentId])

  useEffect(async () => {
    if (!isEmpty(detailObj)) {
      if (detailObj?.audio_type === 'LIVE' && !isLive) {
        return
      }
      if (await checkRestriction()) {
        get_audio_url(detailObj)
      }
    }
  }, [detailObj, isLive, isLogin])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setPlayingAudio(true)
      setIsVideoPlaying(true)
      // audioAnalyzer()
    }
  }, [contentWatchUrl])

  const get_audio_url = (detailObj) => {
    if (detailObj.audio_type === 'LIVE') {
      setPlayingAudio(true)
      setIsVideoPlaying(true)
    } else {
      getContentWatchUrl()
    }
  }

  const checkRestriction = (openModal = false) => {
    if (detailObj.audio_type === 'LIVE' && endTime < Date.now()) {
      return false
    }

    if (!detailObj.is_restricted) {
      if (detailObj.is_login_required && !isLogin) {
        setIsContentSubscribed(false)
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        return false
      }
      if (
        detailObj.is_login_required &&
        isLogin &&
        accountObj?.od_resource_allowances?.verify_email_mandatory &&
        !userObj?.is_email_verified
      ) {
        setVerifyOtpModalData({ title: t('verify_your_email'), email: userObj?.email_id })
        setIsEmailUnVerified(true)
        return false
      }
      return true
    } else {
      if (!isLogin) {
        if (openModal) {
          setModalType('login')
          setOpenLoginModal(true)
        }
        setIsContentSubscribed(false)
        return false
      } else {
        if (
          accountObj?.od_resource_allowances?.verify_email_mandatory &&
          !userObj?.is_email_verified
        ) {
          setVerifyOtpModalData({ title: t('verify_your_email'), email: userObj?.email_id })
          setIsEmailUnVerified(true)
          return false
        }

        if (detailObj?.is_purchased_required) {
          let contentSub = [
            ...detailObj.active_subscription.map((item) => item.id),
            ...detailObj.active_ppv_subscription.map((item) => item.id),
          ]
          setIsContentSubscribed(false)
          if (openModal) {
            setRedirectionUrl(window.location.pathname)
            setSubscriptions(contentSub)
            setOpenSubscriptionModal(true)
          }
        } else {
          return true
        }
        return false
      }
    }
  }

  const onCounterComplete = () => {
    setIsLive(true)
  }

  useInterval(
    async () => {
      if (endTime) {
        const time = Date.now()

        if (time >= endTime) {
          setIsLive(false)
          setPlayingAudio(false)
          setIsVideoPlaying(false)
        }
      }
    },
    endTime && Date.now() < endTime ? 1000 : null,
  )

  return (
    <div className=" w-full h-full relative ">
      {playingAudio ? (
        <>
          {analyzerData && <WaveForm analyzerData={analyzerData} />}
          <CustomImage
            imageUrl={getImage(accountObj?.cf_domain_name, detailObj.thumbnail)}
            imgClass="w-full h-full "
            loaderClass={'h-full'}
            imgAlt={'thumbnail'}
          />

          <ReactAudioPlayer
            ref={audioElmRef}
            autoPlay
            crossOrigin={'anonymous'}
            src={contentWatchUrl && contentWatchUrl.data.signed_url}
            onPlay={(e) => {
              if (!flag) {
                audioAnalyzer()
              }
            }}
            className="z-20 absolute bottom-0 text-white"
            style={{ backgroundColor: '#00000090', color: '#fff' }}
            showSkipControls={handlePrev && handleNext}
            onClickNext={handleNext}
            onClickPrevious={handlePrev}
            // other props here
          />
        </>
      ) : (
        <div className=" w-full h-full bg-black">
          {detailObj.audio_type.includes('LIVE') &&
            !isLive &&
            (detailObj.audio_type === 'VOD_LIVE' || endTime > Date.now()) && (
              <div className="flex relative justify-center w-full h-full">
                <LiveCounter
                  styles={{ top: '32%' }}
                  counterClass={' w-4/5 lg:w-fit'}
                  contentDetail={detailObj}
                  handleCompletion={onCounterComplete}
                />
              </div>
            )}
          {detailObj.audio_type === 'LIVE' && endTime < Date.now() && (
            <div className="w-full h-full flex justify-center items-center ">
              <button
                style={{ background: '#09e9e9', color: 'whitesmoke' }}
                className="w-fit text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg py-1 px-3"
                disabled={true}
              >
                {t('stream_ended')}
              </button>
            </div>
          )}

          {(!isContentSubscribed || isEmailUnVerified) && (
            <div className="w-full h-full flex justify-center items-center ">
              <div className=" w-4/5 md:w-fit">
                <div className="flex px-5 md:px-0">
                  <img
                    src={LockIcon}
                    style={{
                      width: '18.5px',
                      height: '24.6px',
                    }}
                    alt="LockIcon"
                  />
                  <p
                    style={{ color: '#cecece' }}
                    className="md:ml-2 text-xs md:text-base xl:text-lg text-center "
                  >
                    {isEmailUnVerified ? t('account_not_verified') : t('not_include_in_membership')}
                  </p>
                </div>
                <button
                  style={{ background: '#09e9e9', color: '#000' }}
                  className="w-full text-xs md:text-base xl:text-lg rounded-md xl:rounded-lg mt-4 xl:mt-6 py-1"
                  onClick={() => checkRestriction(true)}
                >
                  {isEmailUnVerified ? t('verify_account') : t('upgrade_membership_to_watch')}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AudioPlayer
