import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import CustomImage from '../../components/customImage/customImage'
import HeroImg from '../../assets/pricing-summary-hero.jpeg'
import { withTheme } from 'theming'
import ApplyDiscountImage from '../../assets/imgComponents/applyDiscountImage'
import { getPaymentGatewayLogo, isEmpty, raiseToast } from '../../utils/helper'
import ChevronRight from '../../assets/imgComponents/chevronRight'
import DiscountModal from '../../components/pricing/discountModal'
import { AppContext } from '../../context/app'
// import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import { SubscriptionContext } from '../../context/subscription'
import { CREATE_TRANSACTION, GET_DISCOUNTS, GET_ESTIMATE, PAYEMENT_ORGS } from '../../utils/apiUrls'
import { displayRazorpay } from './paymentSDK/razorPay'
import { useTranslation } from 'react-i18next'

const PaymentPage = ({ theme }) => {
  const { t } = useTranslation()
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState('')
  const [paymentGatewaySelector, setPaymentGatewaySelector] = useState(false)

  const { setLoader, accountObj } = useContext(AppContext)
  const [searchParams] = useSearchParams()
  // const { t } = useTranslation()
  const { availableSubscription, redirectionUrl } = useContext(SubscriptionContext)
  const [estimateInfo, setEstimateInfo] = useState([])
  const [estimationPayload, setEstimationPayload] = useState([])

  const navigate = useNavigate()

  const [userDiscounts, setUserDiscounts] = useState([])
  const [discountModalOpen, setDiscountModalOpen] = useState(false)

  const [verifiedPaymentOrgs, setVerifiedPaymentOrgs] = useState([])
  const [currency, setCurrency] = useState('')
  const [discountObj, setDiscountObj] = useState({})

  let planId = useParams().planId
  const subscriptionId = searchParams.get('sub_id', '')

  useEffect(() => {
    if (!isEmpty(availableSubscription)) {
      getEstimation('', '')
    }
  }, [planId, availableSubscription, subscriptionId])

  const getEstimation = (discountCode, discountId) => {
    const payload = [
      {
        subscription_id: planId,
        discount_code: discountCode,
        discount_id: discountId,
      },
    ]
    if (subscriptionId) {
      payload.push({
        subscription_id: subscriptionId,
        discount_code: '',
        discount_id: '',
      })
    }
    setEstimationPayload(payload)
    estimateCall({
      data: payload,
    })
  }

  const [{ data: paymentOrgList, loading: paymentOrgListLoading }] = useAxios({
    url: PAYEMENT_ORGS,
    method: 'get',
  })

  const [{ data: discountList, loading: discountListLoading }] = useAxios({
    url: GET_DISCOUNTS,
    method: 'get',
    params: {
      plan_id: planId,
    },
  })

  const [{ data: estimateRes, loading: estimateLoading }, estimateCall] = useAxios(
    {
      url: GET_ESTIMATE,
      method: 'post',
    },
    { manual: true },
  )

  const [{ data: createTransactionRes, loading: createTransactionLoading }, createTransactionCall] =
    useAxios(
      {
        url: CREATE_TRANSACTION,
        method: 'post',
      },
      { manual: true },
    )

  useEffect(() => {
    setLoader(
      estimateLoading || paymentOrgListLoading || discountListLoading || createTransactionLoading,
    )
  }, [estimateLoading, createTransactionLoading, paymentOrgListLoading, discountListLoading])

  useEffect(() => {
    if (estimateRes && estimateRes.success) {
      setEstimateInfo(estimateRes.data)
      let mainPlan = estimateRes.data.find((item) => item.subscription._id === planId)
      setCurrency(mainPlan.currency)
      if (mainPlan.is_valid_discount) {
        setDiscountModalOpen(false)
        if (mainPlan.discount) {
          setDiscountObj({ ...mainPlan.discount, discount_amount: mainPlan.discount_amount })
        } else {
          setDiscountObj({})
        }
      } else {
        raiseToast('error', estimateRes.data.message)
      }
    }
  }, [estimateRes])

  useEffect(() => {
    if (discountList && discountList.success) {
      setUserDiscounts(
        discountList.data.filter((item) => item.applicable_subscriptions.includes(planId)),
      )
    }
  }, [discountList])

  useEffect(() => {
    if (paymentOrgList && paymentOrgList.success) {
      let tempData = paymentOrgList?.data.filter((item) => item.is_account_verified === true)
      setVerifiedPaymentOrgs([...tempData])
      let doesStripeExist = tempData.filter((item) => item.org === 'Stripe')
      if (doesStripeExist.length) {
        setSelectedPaymentGateway('Stripe')
        return
      }
      setSelectedPaymentGateway(tempData[0].org)
    }
  }, [paymentOrgList])

  useEffect(() => {
    if (createTransactionRes && createTransactionRes.success) {
      let { customer_gateway_info } = createTransactionRes.data
      let selectedOrg = verifiedPaymentOrgs.find((item) => item._id === customer_gateway_info)

      if (selectedOrg.org === 'Razorpay') {
        displayRazorpay(
          createTransactionRes.data._id,
          createTransactionRes.data.initiate_payload,
          navigate,
          accountObj,
          estimateInfo[0]?.subscription?.name,
        )
      } else {
        window.location = createTransactionRes.data.transaction_url
      }
    }
  }, [createTransactionRes])

  // const validateInfo = () => {
  //   let isValid = true
  //   if (isEmpty(estimateInfo) || isEmpty(verifiedPaymentOrgs)) {
  //     isValid = false
  //   }
  //   return isValid
  // }

  const createTransaction = (gatewayId) => {
    let data = {}
    if (isEmpty(subscriptionId)) {
      data = {
        ...estimationPayload[0],
        is_multiple: false,
        customer_gateway_info_id: gatewayId,
        redirection_url: redirectionUrl,
      }
    } else {
      data = {
        payload: estimationPayload,
        is_multiple: true,
        customer_gateway_info_id: gatewayId,
        redirection_url: redirectionUrl,
      }
    }
    createTransactionCall({ data })
  }

  const handlePayNow = async () => {
    if (!isEmpty(selectedPaymentGateway)) {
      createTransaction(verifiedPaymentOrgs.find((item) => item.org === selectedPaymentGateway)._id)
    } else {
      raiseToast('error', 'Please select a payment gateway')
    }
  }

  const getSubTotal = () => {
    let subTotal = 0
    if (!isEmpty(estimateInfo)) {
      estimateInfo.forEach((est) => {
        subTotal += parseFloat(est?.subscription?.amount)
      })
    }
    return subTotal
  }

  const getTotalDue = () => {
    let totalDue = 0
    if (!isEmpty(estimateInfo)) {
      estimateInfo.forEach((est) => {
        totalDue += parseFloat(est?.transaction_amount)
      })
    }
    return totalDue
  }

  const getTotalTax = () => {
    let taxAmount = 0
    if (!isEmpty(estimateInfo)) {
      estimateInfo.forEach((est) => {
        taxAmount += parseFloat(est?.tax_amount)
      })
    }
    return taxAmount
  }

  return (
    <>
      <div className="relative">
        <div className=" w-full flex justify-center ">
          <div className="mt-6 xl:mt-[115px] flex flex-col items-center w-full z-10">
            <p className="mb-0 text-white xl:text-[28px] font-bold uppercase">
              {t('price_summary')}
            </p>
            <p style={{ color: '#b5b5b5' }} className="mb-0 text-xs lg:text-sm italic">
              {t('please_find_details_below_each_individual')}
            </p>
            <div className="mt-4 w-[94%] lg:w-[905px]">
              <div
                style={{
                  backgroundImage: 'linear-gradient(to right, #000 8%, #00224a 94%)',
                }}
                className="w-full rounded-xl py-7 text-white flex flex-col justify-center items-center"
              >
                <div className="w-[94%] sm:w-[414px] text-xs lg:text-base">
                  <p style={{ color: '#878787' }} className="text-xs">
                    {t('order_details')}
                  </p>
                  {!isEmpty(estimateInfo) &&
                    estimateInfo.map((est, idx) => (
                      <div className="w-full flex justify-between mt-5" key={idx}>
                        <div className="w-4/5">
                          <p className="mb-0 font-bold  line-clamp-1">{est?.subscription?.name}</p>
                          <span style={{ color: '#878787' }} className="text-xs">
                            {t('validity')} {est?.subscription?.duration} {t('days')}
                          </span>
                        </div>
                        <p className="mb-0 font-bold">
                          {parseFloat(est?.subscription?.amount).toFixed(2)}
                          <span style={{ color: '#878787' }} className="text-xs ml-1 font-normal">
                            {currency}
                          </span>
                        </p>
                      </div>
                    ))}
                  <div
                    style={{ border: '0.5px solid #707070', margin: '11px 0px' }}
                    className=" w-full"
                  />
                  <div className="w-full flex justify-between">
                    <p className="mb-0 font-bold w-3/4 line-clamp-1">{t('grand_total')}</p>
                    <p className="mb-0 font-bold">
                      {parseFloat(getSubTotal()).toFixed(2)}
                      <span style={{ color: '#878787' }} className="text-xs ml-1 font-normal">
                        {currency}
                      </span>
                    </p>
                  </div>
                  {!isEmpty(discountObj) && (
                    <>
                      <div className="w-full flex justify-between mt-3">
                        <div className="w-4/5">
                          <div className="mb-0 font-bold flex items-center">
                            <ApplyDiscountImage />
                            <p className="ml-1">{t('coupon')}</p>
                            <p
                              className="ml-1 text-xs mt-1 font-normal"
                              style={{ color: '#29d500' }}
                            >
                              {discountObj.name}
                            </p>
                          </div>
                          <span style={{ color: '#878787' }} className="text-xs">
                            {discountObj.value}% {t('discount')}
                          </span>
                        </div>
                        <p className="mb-0 font-bold" style={{ color: '#29d500' }}>
                          - {parseFloat(discountObj.discount_amount).toFixed(2)}
                          <span style={{ color: '#878787' }} className="text-xs ml-1 font-normal">
                            {currency}
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-end items-center">
                        <p
                          style={{ color: '#fb621e' }}
                          className="mb-0 font-bold text-xs cursor-pointer"
                          onClick={() => getEstimation('', '')}
                        >
                          {t('remove_discount')}
                        </p>
                      </div>
                    </>
                  )}
                  {getTotalTax() !== 0 && (
                    <div className="w-full flex justify-between mt-3">
                      <div className="w-4/5">
                        <p className="mb-0 font-bold  line-clamp-1">{t('TaxTax')}</p>
                        {/*<span style={{ color: '#878787' }} className="text-xs">*/}
                        {/*  (12% inclusive)*/}
                        {/*</span>*/}
                      </div>
                      <p className="mb-0 font-bold">
                        {parseFloat(getTotalTax()).toFixed(2)}
                        <span style={{ color: '#878787' }} className="text-xs ml-1 font-normal">
                          {currency}
                        </span>
                      </p>
                    </div>
                  )}
                  <div
                    style={{ border: '0.5px solid #707070', margin: '11px 0px' }}
                    className=" w-full"
                  />
                  <div className="w-full flex justify-between">
                    <p className="mb-0 line-clamp-1 text-xs" style={{ color: '#878787' }}>
                      {t('total_due')}
                    </p>
                    <p className="mb-0 font-bold">
                      {parseFloat(getTotalDue()).toFixed(2)}
                      <span style={{ color: '#878787' }} className="text-xs ml-1 font-normal">
                        {currency}
                      </span>
                    </p>
                  </div>
                  <div
                    className="mt-5 w-full flex justify-between cursor-pointer"
                    onClick={() => setDiscountModalOpen(true)}
                  >
                    <div className="flex items-center">
                      <ApplyDiscountImage imgClass="lg:mt-0" />
                      <p className="mb-0 font-bold ml-2 text-xs lg:text-sm">
                        {t('apply_coupon_discount')}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full  justify-center mt-10 hidden lg:flex ">
                  {!isEmpty(verifiedPaymentOrgs) &&
                    verifiedPaymentOrgs.map((info, index) => {
                      let isActive = info.org === selectedPaymentGateway
                      return (
                        <div
                          className={`cursor-pointer ${
                            index && 'ml-9'
                          } rounded  px-4 py-1 flex justify-center items-center relative`}
                          style={{
                            width: '154px',
                            height: '36px',
                            objectFit: 'contain',
                            background: isActive ? '#fff' : '#acacac',
                          }}
                          key={index}
                          onClick={() => {
                            setSelectedPaymentGateway(info.org)
                          }}
                        >
                          <img
                            src={getPaymentGatewayLogo(info.org)}
                            alt={info.org}
                            className=" h-full"
                          />
                          {isActive && (
                            <div
                              style={{ background: '#29d500' }}
                              className=" absolute right-0 top-0 m-1 w-3 h-3 rounded-full flex justify-center items-center text-center text-[10px] text-black p-0.5"
                            >
                              ✓
                            </div>
                          )}
                        </div>
                      )
                    })}
                </div>
              </div>

              <div className="w-full mt-6 flex justify-between relative lg:justify-center">
                {paymentGatewaySelector && (
                  <div
                    style={{ background: 'white', bottom: 0 }}
                    className="absolute z-10 lg:hidden w-full px-1.5 py-2 rounded-lg"
                  >
                    <p style={{ color: '#131313' }} className="text-xs px-[23px]">
                      {t('select_payment_gateway')}
                    </p>
                    <div className="mt-5">
                      {!isEmpty(verifiedPaymentOrgs) &&
                        verifiedPaymentOrgs.map((info, index) => {
                          return (
                            <div
                              className={` ${
                                index && 'mt-2'
                              }  px-[23px] flex items-center justify-start `}
                              key={index}
                              style={{ height: '27px', borderBottom: '0.25px solid #70707080' }}
                              onClick={() => {
                                setSelectedPaymentGateway(info.org)
                                setPaymentGatewaySelector(false)
                              }}
                            >
                              <img
                                src={getPaymentGatewayLogo(info.org)}
                                alt={info.org}
                                className=""
                                style={{ maxHeight: '18px' }}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )}
                <div
                  style={{ background: 'white' }}
                  className=" cursor-pointer py-2 px-1.5  w-[40%] text-center rounded flex justify-between items-center lg:hidden"
                  onClick={() => setPaymentGatewaySelector(!paymentGatewaySelector)}
                >
                  <span className="text-[10px]" style={{ color: '#878787' }}>
                    {t('pay_using')}
                  </span>
                  <div
                    style={{ objectFit: 'contain', maxWidth: '66px' }}
                    className="flex items-center justify-center"
                  >
                    <img
                      src={getPaymentGatewayLogo(selectedPaymentGateway)}
                      className=""
                      alt="selectedPaymentGateway"
                    />
                  </div>
                  <div className="">
                    <ChevronRight imgClass="-rotate-90" color="#3b3b3b" />
                  </div>
                </div>
                <div
                  style={{ background: '#bbfa33', color: '#0d0d0d', height: '36px' }}
                  className="cursor-pointer  w-[55%] lg:w-[470px] text-center flex
                  justify-center items-center lg:font-bold rounded"
                  onClick={() => handlePayNow()}
                >
                  {t('pay_now')}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="hidden xl:block absolute top-0 w-full"
          style={{
            aspectRatio: '1920/322',
          }}
        >
          <div className="relative w-full">
            <CustomImage imageUrl={HeroImg} imgClass="w-full h-full" loaderClass={'h-64'} />
            <div
              style={{
                height: '191px',
                backgroundImage: `linear-gradient(to bottom, rgba(27, 36, 47, 0), rgba(27, 36, 47, 0.91) 70%, ${theme.c1})`,
              }}
              className="w-full absolute bottom-0"
            />
          </div>
        </div>
      </div>
      {discountModalOpen && (
        <DiscountModal
          currency={!isEmpty(estimateInfo) ? estimateInfo[0]?.currency : ''}
          discountData={userDiscounts}
          getEstimation={getEstimation}
          onCancel={() => setDiscountModalOpen(false)}
        />
      )}
    </>
  )
}

export default withTheme(PaymentPage)
