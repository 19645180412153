import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AppContext } from '../../context/app'
import {
  convertDuration,
  create_share_url,
  getAudioTrailerUrl,
  getImage,
  getReleaseYear,
  getRemainingTime,
  raiseToast,
} from '../../utils/helper'
import './contentDetail.css'
import TrailerPlayer from '../player/trailerPlayer'
import RedCircle from '../../assets/red_circle.gif'
import GreyCircle from '../../assets/greycircle.png'
import Player from '../player/player'
import useAxios from 'axios-hooks'
import { SubscriptionContext } from '../../context/subscription'
import { isEmpty } from 'lodash'
import { CONTENT_WATCH_URL, DETAIL_CONTENT } from '../../utils/apiUrls'
import { useLocation, useSearchParams } from 'react-router-dom'
import LiveCounter from './liveCounter'
import { useTranslation } from 'react-i18next'
import CustomImage from '../../components/customImage/customImage'
import VideoPlaceholder from '../../assets/video-content.png'
import { withTheme } from 'theming'
import ShareModal from '../../components/shareModal/shareModal'
import ContentDetailBottom from './contentDetailBottom'
import { ContentContext } from '../../context/content'
import AudioTrailerPlayer from '../../components/audioPlayer/audioTrailerPlayer'
import PlayIcon from '../../assets/imgComponents/playIcon'
import NewShareIcon from '../../assets/imgComponents/newShareIcon'
import RestartIcon from '../../assets/imgComponents/restartIcon'
import NewAddToWatchList from '../../assets/imgComponents/newAddToWatchlist'
import NewAddToFavorite from '../../assets/imgComponents/newAddToFavorite'
import BriefcaseIcon from '../../assets/imgComponents/briefcaseIcon'
import CrownIcon from '../../assets/imgComponents/crownIcon'
import AggregateRatingReviewWidget from '../../components/reviewRating/aggregateRatingReviewWidget'
import NewAddedToFavorite from '../../assets/imgComponents/newAddedToFavorite'
import NewAddedToWatchList from '../../assets/imgComponents/newAddedToWatchlist'
import ListenIcon from '../../assets/imgComponents/listenIcon'
import ShowMoreText from 'react-show-more-text'
import ProgressBar from '../../components/progressBar/progressBar'
import CustomMetadata from '../../components/customMetadata/customMetadata'

const NewContentDetail = (props) => {
  let { theme, data } = props

  console.log('props', data)

  const contentId = useParams().contentId
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { t } = useTranslation()
  const {
    isCustomTemplate,
    isLogin,
    setLoader,
    setOpenLoginModal,
    setModalType,
    modulePermissions,
    accountObj,
    userObj,
    setVerifyOtpModalData,
  } = useContext(AppContext)
  const { setOpenSubscriptionModal, setSubscriptions, setRedirectionUrl } =
    useContext(SubscriptionContext)
  const {
    isInFavoriteList,
    isInWatchList,
    addRemoveFavouriteList,
    addRemoveWatchList,
    setIsVideoPlaying,
    getResumeTimestamp,
  } = useContext(ContentContext)

  const [publishDateTime, setPublishDateTime] = useState(null)
  const [playingVideo, setPlayingVideo] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [detailObj, setDetailObj] = useState()
  const [isLive, setIsLive] = useState(false)
  const [resumeTime, setResumeTime] = useState(0)
  const navigate = useNavigate()
  const isWatch = searchParams.get('watch') === 'true'
  const [endTime, setEndTime] = useState(null)
  const [shareModal, setShareModal] = useState(false)
  const [tooltipHover, setTooltipHover] = useState('')

  const [{ data: contentDetail, loading: contentLoading }, contentDetailCall] = useAxios(
    {
      url: `${DETAIL_CONTENT}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: contentWatchUrl, loading: contentWatchUrlLoading }, getContentWatchUrl] = useAxios(
    {
      url: `${CONTENT_WATCH_URL}${contentId}`,
      method: 'get',
    },
    { manual: true },
  )

  // useInterval(async () => {
  //   if (accountObj?.od_resource_allowances?.content_refresh) {
  //     await contentDetailCall()
  //   }
  // }, 10000)

  useEffect(() => {
    if (!playingVideo) {
      contentDetailCall()
    }
  }, [playingVideo, contentId, isLogin])

  useEffect(() => {
    if (playingVideo) {
      setPlayingVideo(false)
    }
  }, [contentId])

  useEffect(() => {
    if (!isEmpty(detailObj) && isWatch) {
      handleWatchNow()
    }
  }, [detailObj])

  useEffect(() => {
    if (contentDetail && contentDetail.success) {
      const contentObj = contentDetail.data
      setShowMore(false)
      setDetailObj(contentObj)
      if (contentObj?.video_type?.includes('LIVE') || contentObj?.audio_type?.includes('LIVE')) {
        let publishTime = new Date(contentObj.publish_time + 'Z')
        setPublishDateTime(new Date(contentObj.publish_time + 'Z').getTime())

        if (contentObj?.video_type === 'VOD_LIVE') {
          setEndTime(
            new Date(
              publishTime.setSeconds(publishTime.getSeconds() + contentObj.duration_in_s),
            ).getTime(),
          )
        } else {
          setEndTime(new Date(contentObj.live_expiry_time + 'Z').getTime())
        }

        if (
          (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
            new Date(
              publishTime.setSeconds(publishTime.getSeconds() + contentObj.duration_in_s),
            ).getTime() > Date.now() &&
            contentObj?.video_type === 'VOD_LIVE') ||
          (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
            new Date(contentObj.live_expiry_time + 'Z').getTime() > Date.now())
        ) {
          setIsLive(true)
        }
      }
      if (isLogin && !contentDetail?.data?.video_type?.includes('LIVE')) {
        setResumeTime(getResumeTimestamp(contentId))
      }
    }
  }, [contentDetail])

  useEffect(() => {
    if (contentWatchUrl && contentWatchUrl.success) {
      setPlayingVideo(true)
      setIsVideoPlaying(true)
    }
  }, [contentWatchUrl])

  useEffect(() => {
    setLoader((isEmpty(detailObj) && contentLoading) || contentWatchUrlLoading)
  }, [contentLoading, contentWatchUrlLoading])

  const get_video_url = (detailObj) => {
    if (detailObj?.video_type === 'LIVE' || detailObj?.audio_type === 'LIVE') {
      setPlayingVideo(true)
      setIsVideoPlaying(true)
    } else {
      getContentWatchUrl()
    }
  }

  const handleWatchNow = () => {
    if (
      (detailObj.video_type === 'LIVE' || detailObj?.audio_type === 'LIVE') &&
      endTime < Date.now()
    ) {
      raiseToast('error', t('stream_ended_toast'))
      return
    }

    if (!detailObj.is_restricted) {
      if (
        detailObj.is_login_required &&
        isLogin &&
        accountObj?.od_resource_allowances?.verify_email_mandatory &&
        !userObj?.is_email_verified
      ) {
        setVerifyOtpModalData({ title: t('verify_your_email'), email: userObj?.email_id })
        return
      }

      if (detailObj.is_login_required && !isLogin) {
        setModalType('login')
        setOpenLoginModal(true)
      } else {
        if (accountObj?.od_resource_allowances?.player_page) {
          detailObj.video_type === 'MPVC' || detailObj.video_type === 'MPAC'
            ? navigate(`/series-watch/${contentId}`)
            : navigate(`/content-watch/${contentId}`)
          return
        }
        get_video_url(detailObj)
      }
    } else {
      if (!isLogin) {
        setModalType('login')
        setOpenLoginModal(true)
      } else {
        if (
          accountObj?.od_resource_allowances?.verify_email_mandatory &&
          !userObj?.is_email_verified
        ) {
          setVerifyOtpModalData({ title: t('verify_your_email'), email: userObj?.email_id })
          return
        }

        if (detailObj?.is_purchased_required) {
          let activeSub = [...detailObj.active_subscription.map((item) => item.id)]
          let activePPV = [...detailObj.active_ppv_subscription.map((item) => item.id)]

          setRedirectionUrl(window.location.pathname)
          setSubscriptions([...activeSub, ...activePPV])
          setOpenSubscriptionModal(true)
        } else {
          if (accountObj?.od_resource_allowances?.player_page) {
            detailObj.video_type === 'MPVC' || detailObj.video_type === 'MPAC'
              ? navigate(`/series-watch/${contentId}`)
              : navigate(`/content-watch/${contentId}`)

            return
          }
          get_video_url(detailObj)
        }
      }
    }
  }

  const getButtonText = () => {
    if (!isLogin || !detailObj.is_restricted || !detailObj?.is_purchased_required) {
      if (detailObj?.asset_type === 'AUDIO') {
        return t('listen_now')
      }
      return resumeTime ? t('resume_watching') : t('watch_now')
    } else {
      return t('upgrade_plan')
    }
  }

  const handleCategoryClick = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const handleSubCategory = (id, name) => {
    navigate(`/metadata/${id}`)
  }

  const onCounterComplete = () => {
    setIsLive(true)
    handleWatchNow()
  }

  const buyTicket = () => {
    if (!isLogin) {
      setModalType('login')
      setOpenLoginModal(true)
    } else {
      let activeSub = [
        ...detailObj.active_subscription.map((item) => item.id),
        ...detailObj.active_ppv_subscription.map((item) => item.id),
      ]
      setSubscriptions(activeSub)
      setOpenSubscriptionModal(true)
      setRedirectionUrl(window.location.pathname)
    }
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <>
      {detailObj && (
        <>
          {playingVideo ? (
            <Player
              contentInfo={detailObj}
              handleClose={() => {
                setPlayingVideo(false)
                setIsVideoPlaying(false)
                setResumeTime(0)
                navigate(location.pathname)
              }}
              contentWatchUrl={
                detailObj.video_type === 'LIVE' || detailObj?.audio_type === 'LIVE'
                  ? detailObj.stream.link
                  : contentWatchUrl.data.signed_url
              }
              resumeTime={resumeTime}
            />
          ) : (
            <>
              <div
                style={{
                  background: getStyleValue('component_background', 'primary_color', theme.c1),
                }}
                className={`flex flex-col-reverse ${
                  data?.aligned === 'RIGHT' ? 'xl:flex-row' : 'xl:flex-row-reverse'
                } xl:justify-between 2xl:px-24 xl:px-14 xl:pt-10 w-full`}
              >
                <div
                  className={`z-10 content-detail-info-box xl:mt-0 mt-6 xl:px-0 md:px-10 px-4`}
                  style={{ color: '#e3e2e2' }}
                >
                  <div
                    className={`flex overflow-hidden ${getStyleValue(
                      'sub_heading',
                      'font_format',
                      '',
                    )}`}
                    style={{
                      color: getStyleValue('sub_heading', 'color', '#bbbbbb'),
                      fontFamily: getStyleValue('sub_heading', 'font', ''),
                    }}
                  >
                    {!isEmpty(detailObj?.content_rating) ? (
                      <div
                        className="flex items-center justify-center px-2 rounded"
                        style={{
                          background: `${getStyleValue('sub_heading', 'bg_color', '#000000')}50`,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {detailObj?.content_rating?.icon?.s3_key && (
                          <div className="">
                            <img
                              src={getImage(
                                accountObj?.cf_domain_name,
                                detailObj?.content_rating?.icon?.s3_key,
                              )}
                              className="w-5 h-5"
                              alt="Rating"
                            />
                          </div>
                        )}
                        <p style={{}} className="ml-1">
                          {detailObj?.content_rating?.name}
                        </p>
                      </div>
                    ) : null}
                    {detailObj?.release_time ? (
                      <p
                        style={{
                          background: `${getStyleValue('sub_heading', 'bg_color', '#000000')}50`,
                        }}
                        className={`${
                          !isEmpty(detailObj?.content_rating) ? 'ml-2 xl:ml-4 2xl:ml-8' : ''
                        } px-2 rounded`}
                      >
                        {getReleaseYear(detailObj.release_time)}
                      </p>
                    ) : null}
                    <div
                      className={` flex ${
                        isEmpty(detailObj?.content_rating) && !detailObj?.release_time
                          ? 'w-full'
                          : 'w-[60%]'
                      }`}
                    >
                      {[...detailObj?.category].map((item, index) => {
                        return index < 2 ? (
                          <p
                            key={index}
                            onClick={() => handleCategoryClick(item.id, item.name)}
                            className={`cursor-pointer ${
                              isEmpty(detailObj?.content_rating) &&
                              !detailObj?.release_time &&
                              !index
                                ? ''
                                : 'ml-2 xl:ml-4 2xl:ml-8'
                            }  line-clamp-1 px-2 rounded`}
                            style={{
                              background: `${getStyleValue(
                                'sub_heading',
                                'bg_color',
                                '#000000',
                              )}50`,
                            }}
                          >
                            {item.name}
                          </p>
                        ) : null
                      })}
                    </div>
                  </div>
                  <p
                    style={{
                      color: getStyleValue('content_title', 'color', ''),
                      fontFamily: getStyleValue('content_title', 'font', ''),
                      lineHeight: 'normal',
                    }}
                    className={`text-3xl md:text-[26px] 2xl:text-[38px] ${getStyleValue(
                      'content_title',
                      'font_format',
                      'font-semibold',
                    )} mt-5`}
                  >
                    {detailObj?.title}
                  </p>

                  {modulePermissions?.rating && (
                    <div className="mt-3">
                      <AggregateRatingReviewWidget
                        value={detailObj.average_rating}
                        ratingCount={detailObj.review_count}
                        starColor={getStyleValue('ratings_reviews', 'star_color', '#ffee00')}
                        bigTextColor={getStyleValue('ratings_reviews', 'text1_color', '#fff')}
                        smallTextColor={getStyleValue('ratings_reviews', 'text2_color', '#bbb')}
                      />
                    </div>
                  )}

                  <div className={`flex items-start mt-5`}>
                    {detailObj?.video_type?.includes('LIVE') ||
                    detailObj?.audio_type?.includes('LIVE') ? (
                      <div style={{ display: 'flex', alignItems: 'center' }} className={''}>
                        <button
                          className="px-2.5 2xl:px-4 py-2 text-xs md:text-sm 2xl:text-lg focus:outline-none flex items-center"
                          style={{
                            color: !isLive
                              ? getStyleValue('play_button_live', 'button_text', '#bbbbbb')
                              : getStyleValue('play_button', 'button_text', '#000'),
                            backgroundColor: !isLive
                              ? 'transparent'
                              : getStyleValue('play_button', 'background_color1', '#bbfa33'),
                            borderRadius: '6px',
                            border: !isLive
                              ? `solid 1px ${getStyleValue(
                                  'play_button_live',
                                  'border_color',
                                  '#bbfa33',
                                )} `
                              : 'none',
                          }}
                          onClick={handleWatchNow}
                          disabled={
                            publishDateTime > Date.now() ||
                            (endTime < Date.now() && detailObj?.video_type.includes('LIVE'))
                          }
                        >
                          {isLive && (
                            <div className="mr-2">
                              {detailObj?.asset_type === 'AUDIO' ? (
                                <ListenIcon
                                  color={getStyleValue('play_button', 'play_icon_color', '#000')}
                                />
                              ) : (
                                <PlayIcon
                                  color={getStyleValue('play_button', 'play_icon_color', '#000')}
                                />
                              )}
                            </div>
                          )}
                          {endTime < Date.now() && (
                            <div
                              className="mr-2"
                              style={{
                                width: '14px',
                                height: '14px',
                                background: getStyleValue(
                                  'play_button_live',
                                  'icon_color',
                                  '#bbfa33',
                                ),
                              }}
                            />
                          )}

                          {endTime < Date.now() &&
                          (detailObj?.video_type.includes('LIVE') ||
                            detailObj?.audio_type === 'LIVE')
                            ? t('stream_ended')
                            : !isLive
                            ? t('streaming_soon')
                            : detailObj.video_type === 'LIVE' || detailObj?.audio_type === 'LIVE'
                            ? t('streaming_live')
                            : t('watch_now')}
                          {endTime < Date.now() ? (
                            ''
                          ) : (
                            <img
                              src={!isLive ? GreyCircle : RedCircle}
                              style={{ width: '12px', height: '12px' }}
                              alt={'live'}
                              className="ml-2"
                            />
                          )}
                        </button>
                        {(detailObj.video_type === 'LIVE' || detailObj?.audio_type === 'LIVE') &&
                          detailObj.is_restricted &&
                          detailObj?.is_purchased_required && (
                            <button
                              className="px-4 py-1 ml-2 md:text-sm"
                              style={{
                                color: getStyleValue('play_button', 'button_text', '#000'),
                                backgroundImage: `linear-gradient(to left , ${getStyleValue(
                                  'play_button',
                                  'background_color1',
                                  '#bbfa33',
                                )}, ${getStyleValue(
                                  'play_button',
                                  'background_color2',
                                  '#bbfa33',
                                )})`,
                                borderRadius: '6px',
                              }}
                              onClick={buyTicket}
                            >
                              {t('buy_ticket')}
                            </button>
                          )}
                      </div>
                    ) : (
                      <div className="">
                        <button
                          className="px-2.5 2xl:px-4 py-2 text-xs md:text-sm 2xl:text-lg focus:outline-none flex items-center"
                          style={{
                            color: getStyleValue('play_button', 'button_text', '#000'),
                            backgroundImage: `linear-gradient(to left , ${getStyleValue(
                              'play_button',
                              'background_color1',
                              '#bbfa33',
                            )}, ${getStyleValue('play_button', 'background_color2', '#bbfa33')})`,

                            // backgroundColor:getStyleValue('ratings_reviews', 'text1_color', '#fff') '#bbfa33',
                            borderRadius: '6px',
                          }}
                          onClick={() => {
                            // setResumeTime(detailObj?.resume_timestamp)
                            handleWatchNow()
                          }}
                        >
                          <div className="mr-2">
                            {detailObj?.asset_type === 'AUDIO' ? (
                              <ListenIcon
                                color={getStyleValue('play_button', 'play_icon_color', '#000')}
                              />
                            ) : (
                              <PlayIcon
                                color={getStyleValue('play_button', 'play_icon_color', '#000')}
                              />
                            )}
                          </div>{' '}
                          {getButtonText()}
                        </button>
                        {getResumeTimestamp(contentId) &&
                        getRemainingTime(
                          detailObj?.duration_in_s,
                          getResumeTimestamp(contentId),
                        ) ? (
                          <div className="px-2.5 2xl:px-4">
                            <ProgressBar
                              bgColor={getResumeTimestamp(contentId) ? '#bbbbbb' : 'transparent'}
                              barClass={'mb-2 mt-2'}
                              progressWidth={`${Number(
                                (getResumeTimestamp(contentId) / detailObj?.duration_in_s) * 100,
                              )}%`}
                              progressColor={
                                getResumeTimestamp(contentId)
                                  ? getStyleValue('play_button', 'background_color1', '#bbfa33')
                                  : 'transparent'
                              }
                            />
                            <p className="italic text-xs text-center">
                              {getRemainingTime(
                                detailObj?.duration_in_s,
                                getResumeTimestamp(contentId),
                              )}
                              {t('left')}
                            </p>
                          </div>
                        ) : (
                          <p className="italic text-xs text-center">
                            {convertDuration(detailObj?.duration_in_s)}
                          </p>
                        )}
                      </div>
                    )}
                    <div className={`flex`}>
                      <div
                        style={{
                          background: `${getStyleValue(
                            'operational_icon',
                            'operational_icon_bg_color',
                            '#070707',
                          )}50`,
                        }}
                        className="flex px-4 py-3 items-center rounded-lg ml-4"
                      >
                        {resumeTime ? (
                          <div
                            className=" cursor-pointer relative"
                            onClick={handleWatchNow}
                            onMouseOver={() => setTooltipHover('RESUME')}
                            onMouseLeave={() => setTooltipHover('')}
                          >
                            <RestartIcon
                              color={getStyleValue(
                                'operational_icon',
                                'operational_icon_color',
                                '#fff',
                              )}
                              hoverColor={getStyleValue(
                                'operational_icon',
                                'operational_icon_hover_color',
                                '#bbfa33',
                              )}
                            />
                            {tooltipHover === 'RESUME' && (
                              <div
                                style={{
                                  left: '15px',
                                  top: '2px',
                                  background: `${getStyleValue(
                                    'operational_icon',
                                    'operational_icon_bg_color',
                                    '#070707',
                                  )}`,
                                  border: `1px solid ${getStyleValue(
                                    'operational_icon',
                                    'operational_icon_hover_color',
                                    '#bbfa33',
                                  )}`,
                                }}
                                className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 z-10 w-max"
                              >
                                {t('start_over')}
                              </div>
                            )}
                          </div>
                        ) : null}
                        <div
                          className={`${
                            resumeTime ? ' ml-2 xl:ml-4 ' : ''
                          } cursor-pointer relative`}
                          onClick={() => setShareModal(true)}
                          onMouseOver={() => setTooltipHover('SHARE')}
                          onMouseLeave={() => setTooltipHover('')}
                        >
                          <NewShareIcon
                            color={getStyleValue(
                              'operational_icon',
                              'operational_icon_color',
                              '#fff',
                            )}
                            hoverColor={getStyleValue(
                              'operational_icon',
                              'operational_icon_hover_color',
                              '#bbfa33',
                            )}
                          />
                          {tooltipHover === 'SHARE' && (
                            <div
                              style={{
                                left: '15px',
                                top: '2px',
                                background: `${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_bg_color',
                                  '#070707',
                                )}`,
                                border: `1px solid ${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_hover_color',
                                  '#bbfa33',
                                )}`,
                              }}
                              className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 z-10"
                            >
                              {t('share')}
                            </div>
                          )}
                        </div>
                        {isLogin && (
                          <div className="flex items-center justify-between ">
                            <div
                              className=" cursor-pointer ml-2 xl:ml-4 relative"
                              onClick={() => addRemoveWatchList(contentId)}
                              onMouseOver={() => setTooltipHover('WATCHLIST')}
                              onMouseLeave={() => setTooltipHover('')}
                            >
                              {isInWatchList(contentId) ? (
                                <NewAddedToWatchList
                                  color={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_color',
                                    '#fff',
                                  )}
                                  hoverColor={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_hover_color',
                                    '#bbfa33',
                                  )}
                                />
                              ) : (
                                <NewAddToWatchList
                                  color={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_color',
                                    '#fff',
                                  )}
                                  hoverColor={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_hover_color',
                                    '#bbfa33',
                                  )}
                                />
                              )}
                              {tooltipHover === 'WATCHLIST' && (
                                <div
                                  style={{
                                    left: '15px',
                                    top: '0px',
                                    background: `${getStyleValue(
                                      'operational_icon',
                                      'operational_icon_bg_color',
                                      '#070707',
                                    )}`,
                                    border: `1px solid ${getStyleValue(
                                      'operational_icon',
                                      'operational_icon_hover_color',
                                      '#bbfa33',
                                    )}`,
                                  }}
                                  className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 w-max z-10"
                                >
                                  {isInWatchList(contentId)
                                    ? t('remove_from_watchlist')
                                    : t('add_to_watchlist')}
                                </div>
                              )}
                            </div>
                            <div
                              className="cursor-pointer ml-2 xl:ml-4 relative"
                              onClick={() => addRemoveFavouriteList(contentId)}
                              onMouseOver={() => setTooltipHover('FAVORITES')}
                              onMouseLeave={() => setTooltipHover('')}
                            >
                              {isInFavoriteList(contentId) ? (
                                <NewAddedToFavorite
                                  color={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_color',
                                    '#fff',
                                  )}
                                  hoverColor={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_hover_color',
                                    '#bbfa33',
                                  )}
                                />
                              ) : (
                                <NewAddToFavorite
                                  color={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_color',
                                    '#fff',
                                  )}
                                  hoverColor={getStyleValue(
                                    'operational_icon',
                                    'operational_icon_hover_color',
                                    '#bbfa33',
                                  )}
                                />
                              )}
                              {tooltipHover === 'FAVORITES' && (
                                <div
                                  style={{
                                    left: '15px',
                                    top: '0px',
                                    background: `${getStyleValue(
                                      'operational_icon',
                                      'operational_icon_bg_color',
                                      '#070707',
                                    )}`,
                                    border: `1px solid ${getStyleValue(
                                      'operational_icon',
                                      'operational_icon_hover_color',
                                      '#bbfa33',
                                    )}`,
                                  }}
                                  className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 w-max z-10"
                                >
                                  {isInFavoriteList(contentId)
                                    ? t('remove_from_favorites')
                                    : t('add_to_favorites')}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      {detailObj.is_restricted && (
                        <div
                          style={{
                            background: `${getStyleValue(
                              'operational_icon',
                              'informative_icon_bg_color',
                              '#070707',
                            )}50`,
                          }}
                          className="px-4 py-3 ml-4 flex items-center rounded-lg justify-center relative"
                          onMouseOver={() => setTooltipHover('PREMIUM_CONTENT')}
                          onMouseLeave={() => setTooltipHover('')}
                        >
                          {detailObj.is_restricted && detailObj?.is_purchased_required ? (
                            <BriefcaseIcon
                              color={getStyleValue(
                                'operational_icon',
                                'informative_icon_color',
                                '#ffee00',
                              )}
                            />
                          ) : (
                            <CrownIcon
                              color={getStyleValue(
                                'operational_icon',
                                'informative_icon_color',
                                '#ffee00',
                              )}
                            />
                          )}

                          {tooltipHover === 'PREMIUM_CONTENT' && (
                            <div
                              style={{
                                left: '80%',
                                top: '30%',
                                background: `${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_bg_color',
                                  '#070707',
                                )}`,
                                border: `1px solid ${getStyleValue(
                                  'operational_icon',
                                  'operational_icon_hover_color',
                                  '#bbfa33',
                                )}`,
                              }}
                              className="hidden lg:block text-xs px-2.5 rounded absolute ml-3 w-max z-10"
                            >
                              {detailObj.is_restricted && detailObj?.is_purchased_required
                                ? t('premium_content')
                                : t('premium_content_included')}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="xl:max-h-[18.5vw] hidden-scrollbar-class w-full mt-5 ">
                      <p
                        style={{
                          color: getStyleValue('content_description', 'color', '#bbbbbb'),
                          fontFamily: getStyleValue('content_description', 'font', ''),
                        }}
                        className={`${getStyleValue('content_description', 'font_format', '')}`}
                      >
                        <ShowMoreText
                          lines={3}
                          more={
                            <span
                              style={{
                                color: getStyleValue('play_button', 'background_color1', '#bbfa33'),
                              }}
                            >
                              {t('show_more')}
                            </span>
                          }
                          less={
                            <span
                              style={{
                                color: getStyleValue('play_button', 'background_color1', '#bbfa33'),
                              }}
                            >
                              {t('show_less')}
                            </span>
                          }
                          className={` w-full `}
                          anchorClass={`text-sm cursor-pointer `}
                          expanded={showMore}
                          onClick={() => setShowMore((showMore) => !showMore)}
                          truncatedEndingComponent={'... '}
                        >
                          <p>{detailObj?.description}</p>
                        </ShowMoreText>
                      </p>

                      <div
                        style={{
                          color: getStyleValue('metadata_titles', 'color', '#bbbbbb'),
                          fontFamily: getStyleValue('metadata_titles', 'font', ''),
                        }}
                        className={`${getStyleValue('metadata_titles', 'font_format', '')}  mt-5`}
                      >
                        {Object.keys(detailObj.artists).map((artist, index) => (
                          <div className={'flex justify-between mt-2'} key={index}>
                            <p className={'text-sm lg:w-[29%]'}>{artist}</p>
                            <div
                              style={{
                                color: getStyleValue('metadata_values', 'color', '#bbfa33'),
                                fontFamily: getStyleValue('metadata_values', 'font', ''),
                              }}
                              className={`${getStyleValue(
                                'metadata_values',
                                'font_format',
                                'font-semibold',
                              )}   lg:w-[70%] flex flex-col  xl:flex-row`}
                            >
                              <p className=" text-right lg:text-left">
                                {detailObj.artists[artist].map((item, index) => (
                                  <span
                                    key={index}
                                    onClick={() => navigate(`/artist/detail/${item.id}`)}
                                  >
                                    {index !== 0 && <span className={''}>{' , '}</span>}
                                    <span className={'cursor-pointer hover:underline'}>
                                      {item.name}
                                    </span>
                                  </span>
                                ))}
                              </p>
                            </div>
                          </div>
                        ))}
                        {!isEmpty(detailObj.sub_category) && (
                          <div className={'flex justify-between mt-2'}>
                            <p className={' text-sm lg:w-[29%]'}>{t('genre')}</p>
                            <div
                              style={{
                                color: getStyleValue('metadata_values', 'color', '#bbfa33'),
                                fontFamily: getStyleValue('metadata_values', 'font', ''),
                              }}
                              className={`${getStyleValue(
                                'metadata_values',
                                'font_format',
                                'font-semibold',
                              )}   lg:w-[70%] flex flex-col  xl:flex-row`}
                            >
                              <p className=" text-right lg:text-left">
                                {detailObj.sub_category.map((item, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleSubCategory(item.id, item.name)}
                                  >
                                    {index !== 0 && <span className={''}>{' , '}</span>}
                                    <span className={'cursor-pointer hover:underline'}>
                                      {item.name}
                                    </span>
                                  </span>
                                ))}
                              </p>
                            </div>
                          </div>
                        )}
                        {!isEmpty(detailObj.venues) && (
                          <div className={'flex justify-between mt-2'}>
                            <p className={' text-sm  lg:w-[29%]'}>{t('venue')}</p>
                            <div
                              style={{
                                color: getStyleValue('metadata_values', 'color', '#bbfa33'),
                                fontFamily: getStyleValue('metadata_values', 'font', ''),
                              }}
                              className={`${getStyleValue(
                                'metadata_values',
                                'font_format',
                                'font-semibold',
                              )}   lg:w-[70%] flex flex-col  xl:flex-row`}
                            >
                              <p className=" text-right lg:text-left">
                                {detailObj.venues.map((item, index) => (
                                  <span
                                    key={index}
                                    onClick={() => navigate(`/venue/detail/${item.id}`)}
                                  >
                                    {index !== 0 && <span className={''}>{' , '}</span>}
                                    <span className={'cursor-pointer hover:underline'}>
                                      {item.name}
                                    </span>
                                  </span>
                                ))}
                              </p>
                            </div>
                          </div>
                        )}

                        {detailObj.custom_metadata_new ? (
                          <CustomMetadata
                            customMetadata={detailObj.custom_metadata_new}
                            valueColor={getStyleValue('metadata_values', 'color', '#bbfa33')}
                            valueFontFamily={getStyleValue('metadata_values', 'font', '')}
                            valueFontFormat={getStyleValue(
                              'metadata_values',
                              'font_format',
                              'font-semibold',
                            )}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new-content-image  w-full">
                  <div className="new-content-image-div relative xl:rounded-[20px] overflow-hidden ">
                    <div className="absolute xl:ml-10 hidden lg:flex">
                      {(detailObj?.video_type?.includes('LIVE') ||
                        detailObj?.audio_type?.includes('LIVE')) &&
                        !isLive &&
                        endTime > Date.now() && (
                          <LiveCounter
                            contentDetail={detailObj}
                            handleCompletion={onCounterComplete}
                            removeText={true}
                          />
                        )}
                    </div>
                    {(detailObj.video_type === 'LIVE' || detailObj?.audio_type === 'LIVE') &&
                    !isLive ? (
                      <CustomImage
                        imageUrl={getImage(
                          accountObj?.cf_domain_name,
                          detailObj?.asset_type === 'AUDIO'
                            ? detailObj?.thumbnail
                            : detailObj?.poster,
                        )}
                        loaderClass="new-content-loader"
                        imgAlt="content"
                        imgClass="w-full h-full"
                        errorPlaceholder={VideoPlaceholder}
                      />
                    ) : (
                      <>
                        {detailObj.preview ? (
                          detailObj?.trailer_type === 'AUDIO' ? (
                            <AudioTrailerPlayer
                              url={getAudioTrailerUrl(
                                accountObj?.cf_domain_name,
                                detailObj.preview,
                              )}
                              detailObj={detailObj}
                              accountObj={accountObj}
                              isCustomTemplate={isCustomTemplate}
                              theme={theme}
                            />
                          ) : (
                            <div className="">
                              <TrailerPlayer
                                preview={detailObj.preview}
                                poster={getImage(accountObj?.cf_domain_name, detailObj?.poster)}
                                quality={480}
                                type={'CONTENT'}
                                stream={detailObj.stream}
                                videoType={detailObj.video_type}
                                height={'calc(59vw / 1.7777)'}
                              />
                            </div>
                          )
                        ) : (
                          <CustomImage
                            imageUrl={getImage(
                              accountObj?.cf_domain_name,
                              detailObj?.asset_type === 'AUDIO'
                                ? detailObj?.thumbnail
                                : detailObj?.poster,
                            )}
                            loaderClass="new-content-loader"
                            imgAlt="content"
                            imgClass="w-full h-full"
                            errorPlaceholder={VideoPlaceholder}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <ContentDetailBottom
                detailObj={detailObj}
                theme={theme}
                removeMargin={true}
                data={data}
              />
            </>
          )}

          {shareModal && (
            <ShareModal
              onClose={() => setShareModal(false)}
              link={create_share_url()}
              detailObj={detailObj}
            />
          )}
        </>
      )}
    </>
  )
}

export default withTheme(NewContentDetail)
